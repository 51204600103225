<template>
  <div>
    <h1>Query Users/Accounts</h1>
    <form
      class="fc-container-sm fc-flex fc-align-center"
      @submit.prevent="queryUsersAccounts">
      <FCInput
        v-model="query"
        type="text"
        title="Query"
        name="Query" />
      <ButtonAction
        primary
        :disabled="loading"
        @click.native="queryUsersAccounts">
        {{ loading ? 'Loading...' : 'Query' }}
      </ButtonAction>
    </form>
    <p v-if="error" class="fc-text-red fc-bold">
      {{ error }}
    </p>
    <div class="fc-container-sm fc-flex fc-column fc-margin-top-10" style="max-width: 1200px;">
      <div class="fc-flex fc-justify-between">
        <h2 class="fc-padding-bottom-2">
          Query Results
        </h2>
        <button
          v-if="selectedUsers.length === 2"
          class="swap-button"
          @click="swapUsers">
          SWAP
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th
              v-for="(header, i) in queryHeaders"
              :key="`${header}${i}`"
              class="fc-text-left">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="entity in queryResults"
            :key="`entity-${entity.userId}`"
            class="fc-align-left">
            <td class="fc-text-left fc-padding-left-2">
              <div v-if="entity.userId">
                <input
                  type="checkbox"
                  :checked="isSelected(entity.userId)"
                  :disabled="isSelectionDisabled(entity.userId)"
                  @change="toggleSelection(entity.userId)">
                <span style="font-weight: bold;">
                  {{ entity.username }}
                </span>
                <br>
                {{ getLoginTypeIcon(entity) }}
                <a :href="`mailto:${entity.email}`">
                  {{ entity.email }}
                </a>
                &nbsp;| <span style="font-family: monospace;">{{ entity.userId }}</span>
              </div>
            </td>
            <td class="fc-text-left fc-padding-left-2">
              <div v-if="entity.accountId">
                <a :href="`mailto:${entity.accountEmail}`">
                  {{ entity.accountEmail }}
                </a>
                &nbsp;| <span style="font-family: monospace;">{{ entity.accountId }}</span>
                &nbsp;| <span :style="{ backgroundColor: entity.hasActiveSubscription ? '#90EE90' : '#FF5733', fontFamily: 'monospace' }">{{ entity.stripeId }}</span>
                <span v-if="canAddToSearch(entity)">
                  | <a @click="addToSearch(entity.accountEmail)">🔍</a>
                </span>
              </div>
            </td>
            <td class="fc-text-left fc-padding-left-2">
              <div v-if="entity.userId">
                <div v-if="entity.accountId">
                  <button :title="`Remove from account ${entity.accountEmail} | ${entity.accountId}`" @click="removeFromAccount(entity)">
                    🗑️ {{ entity.accountId }}
                  </button>
                </div>
                <div v-for="account in getOtherAccounts(entity, queryResults)" :key="account.accountId">
                  <button :title="`Assign to account ${account.accountEmail} | ${account.accountId}`" @click="addToAccount(entity, account)">
                    ➕ {{ account.accountId }}
                  </button>
                </div>
              </div>
            </td>
            <td class="fc-text-center fc-padding-left-2">
              <div v-if="entity.userId && entity.accountId">
                <span v-if="entity.owner">✔️</span>
                <button v-else @click="setAsOwner(entity)">
                  Set as Owner
                </button>
              </div>
            </td>
            <td class="fc-text-left fc-padding-left-2 fc-padding-right-2">
              <span v-if="entity.activationCode">{{ entity.activationCode }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="queryResults.length > 0">
      <div class="fc-container-sm fc-flex fc-column fc-margin-top-10">
        <h2 class="fc-padding-bottom-2">
          Legend
        </h2>
        <table>
          <tbody>
            <tr>
              <td>📚<br>🍏<br>🔑</td>
              <td style="text-align: left;">
                Facebook<br>Apple<br>Password
              </td>
            </tr>
            <tr>
              <td>🔍</td>
              <td style="text-align: left;">
                Add the account email to the search query
              </td>
            </tr>
            <tr>
              <td><span style="font-family: monospace;">🗑️ [accountId]</span></td>
              <td style="text-align: left;">
                Remove a user from an account
              </td>
            </tr>
            <tr>
              <td><span style="font-family: monospace;">➕ [accountId]</span></td>
              <td style="text-align: left;">
                Assign a user to an account
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { FCInput } from '@fc/angie-ui';
  import axios from 'axios';
  import ButtonAction from '@/shared/components/ButtonAction.vue';

  const useLocal = false;
  const netlifyEndpoint = '/.netlify/functions/accountManagement';
  const urls = useLocal ? {
    queryUsersAccounts:
      'http://localhost:5002/admin/users-accounts/query',
    swapUsers:
      'http://localhost:5002/admin/users-accounts/user/authentication/swap',
    setAsOwner:
      'http://localhost:5002/admin/users-accounts/users/:userId/account/owner',
    removeFromAccount:
      'http://localhost:5002/admin/users-accounts/users/:userId/account',
    addToAccount:
      'http://localhost:5002/admin/users-accounts/users/:userId/account',
  } : {
    queryUsersAccounts: netlifyEndpoint,
    swapUsers: netlifyEndpoint,
    setAsOwner: netlifyEndpoint,
    removeFromAccount: netlifyEndpoint,
    addToAccount: netlifyEndpoint,
  };

  export default ({
    name: 'AccountManagement',
    components: {
      FCInput,
      ButtonAction,
    },
    data() {
      return {
        loading: false,
        success: false,
        error: '',
        headers: ['ID', 'Email', 'account', 'account Email', 'owner', 'login'],
        query: '',
        queryResults: [],
        queryHeaders: [
          'User',
          'Account',
          ' ',
          'Owner',
          'Code',
        ],
        selectedUsers: [],
      };
    },
    methods: {
      addToSearch(email) {
        if (!this.query) {
          this.query = email;
          this.queryUsersAccounts();
          return;
        }
        if (this.query.includes(email)) {
          return;
        }
        this.query = `${this.query}, ${email}`;
        this.queryUsersAccounts();
      },
      getLoginTypeIcon(entity) {
        if (entity.loginType === 'Unknown') {
          return '';
        }
        if (entity.loginType === 'Facebook') {
          return '📚';
        }
        if (entity.loginType === 'Apple') {
          return '🍏';
        }
        if (entity.loginType === 'Password') {
          return '🔑';
        }
        return '';
      },
      reset() {
        this.error = '';
        this.selectedUsers = [];
      },
      getOtherAccounts(entity, accounts) {
        const uniqueAccounts = new Map();
        accounts.forEach((account) => {
          if (account.accountId !== entity.accountId && account.accountId) {
            uniqueAccounts.set(account.accountId, account);
          }
        });
        return Array.from(uniqueAccounts.values());
      },
      canAddToSearch(entity) {
        if (!entity.userId) return false;
        if (this.query.includes(entity.accountEmail)) {
          return false;
        }
        return true;
      },
      async setAsOwner(entity) {
        if (entity.userId && entity.accountId) {
          // eslint-disable-next-line no-restricted-globals, no-alert
          if (confirm(`Are you sure you want to set '${entity.username}' as the owner of the account '${entity.accountEmail}'?`)) {
            try {
              await axios.post(urls.setAsOwner.replace(':userId', entity.userId), {
                action: 'setUserAsOwner',
                userId: entity.userId,
                accountId: entity.accountId,
                adminUser: this.$auth.user.email,
              });
              this.queryUsersAccounts();
            } catch (e) {
              this.error = e.message;
            }
          }
        }
      },
      async removeFromAccount(entity) {
        if (entity.userId && entity.accountId) {
          // eslint-disable-next-line no-restricted-globals, no-alert
          if (confirm(`Are you sure you want to remove '${entity.username}' from the account '${entity.accountEmail}'?`)) {
            try {
              await axios.post(urls.removeFromAccount.replace(':userId', entity.userId), {
                action: 'removeUserFromAccount',
                userId: entity.userId,
                adminUser: this.$auth.user.email,
              });
              this.queryUsersAccounts();
            } catch (e) {
              this.error = e.message;
            }
          }
        }
      },
      async addToAccount(entity, account) {
        if (entity.userId && account.accountId) {
          // eslint-disable-next-line no-restricted-globals, no-alert
          if (confirm(`Are you sure you want to add '${entity.username}' in the account '${account.accountEmail}'?`)) {
            try {
              await axios.post(urls.addToAccount.replace(':userId', entity.userId), {
                action: 'addUserToAccount',
                userId: entity.userId,
                accountId: account.accountId,
                adminUser: this.$auth.user.email,
              });
              this.queryUsersAccounts();
            } catch (e) {
              this.error = e.message;
            }
          }
        }
      },
      isSelected(userId) {
        return this.selectedUsers.includes(userId);
      },
      isSelectionDisabled(userId) {
        return this.selectedUsers.length >= 2 && !this.isSelected(userId);
      },
      toggleSelection(userId) {
        if (this.isSelected(userId)) {
          this.selectedUsers = this.selectedUsers.filter(id => id !== userId);
        } else if (this.selectedUsers.length < 2) {
          this.selectedUsers.push(userId);
        }
      },
      async swapUsers() {
        if (this.selectedUsers.length !== 2) return;
        // eslint-disable-next-line no-restricted-globals, no-alert
        if (confirm('Are you sure you want to swap these users?')) {
          try {
            await axios.post(urls.swapUsers, {
              action: 'swapUsers',
              userId1: this.selectedUsers[0],
              userId2: this.selectedUsers[1],
              adminUser: this.$auth.user.email,
            });
            this.selectedUsers = [];
            this.queryUsersAccounts();
          } catch (e) {
            this.error = e.message;
          }
        }
      },
      async queryUsersAccounts() {
        if (this.loading) return;
        this.loading = true;
        try {
          const response = await axios.post(urls.queryUsersAccounts, {
            action: 'queryUsersAccounts',
            query: this.query,
            adminUser: this.$auth.user.email,
          });
          this.queryResults = response.data;
          this.reset();
        } catch (e) {
          this.error = e.message;
        } finally {
          this.loading = false;
        }
      },
    },
    mounted() {
      //
    },
  });
</script>

<style scoped lang="scss">
.gen2-registration {
  &__success-button {
    background-color: green;
  }
}

table {
  border-collapse: collapse;
}
 th {
  background: #ccc;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
}

tr:nth-child(even) {
  background: #efefef;
}

tr:hover {
  background: #d1d1d1;
}

.swap-button {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
}
</style>
